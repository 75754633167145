import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
        'Michroma',
      ].join(','),
    },
});

export default theme;